import {
  addDays,
  addMonths,
  addWeeks,
  endOfISOWeek,
  endOfMonth,
  getISODay,
  getISOWeek,
  getYear,
  startOfISOWeek,
} from 'date-fns';
import { DateRange } from '@/common';

export const getSameDayPrevYear = (date: Date): Date => {
  const dow = getISODay(date);
  const week = getISOWeek(date);
  const year = getYear(date);
  const prev = new Date(year - 1, 0, 1);
  return addDays(prev, (week - 1) * 7 - (getISODay(prev) % 7) + dow);
};

export const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getWeeksInYear = (year: number): number => {
  const firstDay = getISODay(new Date(year, 0, 1));
  if (firstDay === 4 || (firstDay === 3 && isLeapYear(year))) {
    return 53;
  }
  return 52;
};

export const getDateRangeForWeek = (year: number, week: number): DateRange => {
  const startOfYear = new Date(year, 0, 1);
  if (getISODay(startOfYear) > 4) {
    week += 1;
  }
  const d1 = startOfISOWeek(addDays(startOfYear, (week - 1) * 7));
  const d2 = addWeeks(d1, 1);
  return { d1, d2 };
};

export const getWeekRangeForMonth = (year: number, month: number): DateRange => {
  const d1 = startOfISOWeek(new Date(year, month, 1));
  const d2 = addDays(endOfISOWeek(endOfMonth(addMonths(d1, 1))), 1);
  return { d1, d2 };
};
