















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { format, parseISO } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import api from '@/api/generalKPIApi';
import { Dictionary, Establishment, GeneralKPI, Period, PeriodType } from '@/common';
import Loading from '@/components/Loading.vue';
import GeneralKpiChart from './components/GeneralKPIChart.vue';
import GeneralKpiTable from './components/GeneralKPITable.vue';
import { getDateRangeForWeek, getWeeksInYear } from '@/util/date';

const appModule = namespace('app');

interface Data {
  ds1Date: string;
  ds2Date: string;
  ds1Data: GeneralKPI;
  ds2Data: GeneralKPI;
}

@Component({
  components: { Loading, GeneralKpiChart, GeneralKpiTable },
})
export default class WeekOverview extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.State('period')
  public period!: Period;

  @appModule.Action('changePeriod')
  public changePeriod!: (period: Period) => Promise<void>;

  public loading = false;
  public dataset1: Dictionary<GeneralKPI> | null = null;
  public dataset2: Dictionary<GeneralKPI> | null = null;

  public async mounted() {
    await this.loadData();
  }

  get prevWeek() {
    const week = this.period.week - 1;
    if (week < 1) {
      return getWeeksInYear(this.period.year - 1);
    }
    return week;
  }

  get nextWeek() {
    const week = this.period.week + 1;
    if (week > getWeeksInYear(this.period.year)) {
      return 1;
    }
    return week;
  }

  get dataset1Range() {
    return getDateRangeForWeek(this.period.year, this.period.week);
  }

  get dataset2Range() {
    let week = this.period.week;
    if (week === getWeeksInYear(this.period.year)) {
      week = getWeeksInYear(this.period.year - 1);
    }
    return getDateRangeForWeek(this.period.year - 1, week);
  }

  get dataset1Label() {
    return this.period.year;
  }

  get dataset2Label() {
    return this.period.year - 1;
  }

  get categories() {
    if (!this.dataset1) {
      return [];
    }

    return Object.keys(this.dataset1)
      .sort()
      .map(x => ({
        label: format(parseISO(x), 'EEEE', { locale }),
      }));
  }

  get kpi() {
    if (!this.dataset1 || !this.dataset2) {
      return [];
    }

    const data: Data[] = [];
    const ds1Dates = Object.keys(this.dataset1).sort();
    const ds2Dates = Object.keys(this.dataset2).sort();

    const days = Math.min(ds1Dates.length, ds2Dates.length);

    for (let i = 0; i < days; i++) {
      const ds1Date = ds1Dates[i];
      const ds1Data = this.dataset1[ds1Date];
      const ds2Date = ds2Dates[i];
      const ds2Data = this.dataset2[ds2Date];

      data.push({
        ds1Date,
        ds1Data,
        ds2Date,
        ds2Data,
      });
    }

    return data;
  }

  @Watch('establishment')
  public async onEstablishmentChange() {
    await this.loadData();
  }

  @Watch('period', { deep: true })
  public async onPeriodChange() {
    await this.loadData();
  }

  public async loadData() {
    this.loading = true;

    const ds1Start = format(this.dataset1Range.d1, 'yyyy-MM-dd');
    const ds1End = format(this.dataset1Range.d2, 'yyyy-MM-dd');
    const ds2Start = format(this.dataset2Range.d1, 'yyyy-MM-dd');
    const ds2End = format(this.dataset2Range.d2, 'yyyy-MM-dd');

    try {
      this.dataset1 = await api.getPerDay(this.establishment, ds1Start, ds1End);
      this.dataset2 = await api.getPerDay(this.establishment, ds2Start, ds2End);
    } finally {
      this.loading = false;
    }
  }

  public gotoPrevWeek() {
    let week = this.period.week - 1;
    let year = this.period.year;
    if (week < 1) {
      year -= 1;
      week = getWeeksInYear(year);
    }
    this.changePeriod({
      type: PeriodType.WEEK,
      year,
      month: this.period.month,
      week,
      date: this.period.date,
      customStart: this.period.customStart,
      customEnd: this.period.customEnd,
    });
  }

  public gotoNextWeek() {
    let week = this.period.week + 1;
    let year = this.period.year;
    if (week > getWeeksInYear(year)) {
      week = 1;
      year += 1;
    }
    this.changePeriod({
      type: PeriodType.WEEK,
      year,
      month: this.period.month,
      week,
      date: this.period.date,
      customStart: this.period.customStart,
      customEnd: this.period.customEnd,
    });
  }

  public gotoDate(date: string) {
    this.changePeriod({
      type: PeriodType.DAY,
      year: this.period.year,
      month: this.period.month,
      week: this.period.week,
      date,
      customStart: this.period.customStart,
      customEnd: this.period.customEnd,
    });
  }
}
