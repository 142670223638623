import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { AppState } from '.';
import { PeriodType } from '@/common';
import { addDays, addMonths, addYears, parseISO, setYear, startOfYear } from 'date-fns';
import { getDateRangeForWeek } from '@/util/date';

export default {
  selectedRange: state => {
    let d1;
    let d2;
    switch (state.period.type) {
      case PeriodType.DAY:
        d1 = parseISO(state.period.date);
        d2 = addDays(d1, 1);
        return { d1, d2 };
      case PeriodType.WEEK:
        return getDateRangeForWeek(state.period.year, state.period.week);
      case PeriodType.MONTH:
        d1 = new Date(state.period.year, state.period.month, 1);
        d2 = addMonths(d1, 1);
        return { d1, d2 };
      case PeriodType.YEAR:
        d1 = startOfYear(setYear(new Date(), state.period.year));
        d2 = addYears(d1, 1);
        return { d1, d2 };
      default:
        return null;
    }
  },
} as GetterTree<AppState, RootState>;
