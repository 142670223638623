
















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  endOfISOWeek,
  format,
  getISOWeek,
  getMonth,
  getYear,
  parseISO,
  startOfISOWeek,
} from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import api from '@/api/generalKPIApi';
import { Dictionary, Establishment, GeneralKPI, Period, PeriodType } from '@/common';
import Loading from '@/components/Loading.vue';
import GeneralKpiChart from './components/GeneralKPIChart.vue';
import GeneralKpiTable from './components/GeneralKPITable.vue';
import { getWeekRangeForMonth } from '@/util/date';

const appStore = namespace('app');

interface Data {
  ds1Date: string;
  ds2Date: string;
  ds1Data: GeneralKPI;
  ds2Data: GeneralKPI;
}

@Component({
  components: { Loading, GeneralKpiChart, GeneralKpiTable },
})
export default class MonthOverview extends Vue {
  @appStore.State('establishment')
  public establishment!: Establishment;

  @appStore.State('period')
  public period!: Period;

  @appStore.Action('changePeriod')
  public changePeriod!: (period: Period) => void;

  public loading = false;
  public dataset1: Dictionary<GeneralKPI> | null = null;
  public dataset2: Dictionary<GeneralKPI> | null = null;

  public async mounted() {
    await this.loadData();
  }

  get nextMonth() {
    return new Date(this.period.year, this.period.month + 1, 1);
  }

  get prevMonth() {
    return new Date(this.period.year, this.period.month - 1, 1);
  }

  get dataset1Range() {
    return getWeekRangeForMonth(this.period.year, this.period.month);
  }

  get dataset2Range() {
    return getWeekRangeForMonth(this.period.year - 1, this.period.month);
  }

  get dataset1Label() {
    return this.period.year;
  }

  get dataset2Label() {
    return this.period.year - 1;
  }

  get categories() {
    if (!this.dataset1) {
      return [];
    }

    return Object.keys(this.dataset1)
      .sort()
      .map(x => ({
        label: `week ${format(parseISO(x), 'w', { locale })}`,
      }));
  }

  get kpi() {
    if (!this.dataset1 || !this.dataset2) {
      return [];
    }

    const data: Data[] = [];
    const ds1Dates = Object.keys(this.dataset1).sort();
    const ds2Dates = Object.keys(this.dataset2).sort();

    const weeks = Math.min(ds1Dates.length, ds2Dates.length);

    for (let i = 0; i < weeks; i++) {
      const ds1Date = ds1Dates[i];
      const ds1Data = this.dataset1[ds1Date];
      const ds2Date = ds2Dates[i];
      const ds2Data = this.dataset2[ds2Date];

      data.push({
        ds1Date,
        ds1Data,
        ds2Date,
        ds2Data,
      });
    }

    return data;
  }

  @Watch('establishment')
  public async onEstablishmentChange() {
    await this.loadData();
  }

  @Watch('period', { deep: true })
  public async onPeriodMonthChange() {
    await this.loadData();
  }

  public async loadData() {
    this.loading = true;

    const ds1Start = format(this.dataset1Range.d1, 'yyyy-MM-dd');
    const ds1End = format(this.dataset1Range.d2, 'yyyy-MM-dd');
    const ds2Start = format(this.dataset2Range.d1, 'yyyy-MM-dd');
    const ds2End = format(this.dataset2Range.d2, 'yyyy-MM-dd');

    try {
      this.dataset1 = await api.getPerWeek(this.establishment, ds1Start, ds1End);
      this.dataset2 = await api.getPerWeek(this.establishment, ds2Start, ds2End);
    } finally {
      this.loading = false;
    }
  }

  public weekStartEnd(date: string) {
    const d = parseISO(date);
    const start = startOfISOWeek(d);
    const end = endOfISOWeek(d);
    return `${format(start, 'dd-MM-yyyy')} - ${format(end, 'dd-MM-yyyy')}`;
  }

  public gotoMonth(date: Date) {
    const month = getMonth(date);
    const year = getYear(date);

    this.changePeriod({
      type: PeriodType.MONTH,
      year,
      month,
      week: this.period.week,
      date: this.period.date,
      customStart: this.period.customStart,
      customEnd: this.period.customEnd,
    });
  }

  public gotoDate(date: string) {
    const d = parseISO(date);
    const week = getISOWeek(d);
    const year = getYear(d);

    this.changePeriod({
      type: PeriodType.WEEK,
      year,
      month: this.period.month,
      week,
      date: this.period.date,
      customStart: this.period.customStart,
      customEnd: this.period.customEnd,
    });
  }
}
